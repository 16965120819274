import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from '../../components/Layout/Layout';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';

import { Hero01 } from '../../components/LoopSample/Hero01';
import { Hero } from '../../components/LoopSample/Hero';
import { Hero2 } from '../../components/LoopSample/Hero2';
import { Hero3 } from '../../components/LoopSample/Hero3';
import { Hero4 } from '../../components/LoopSample/Hero4';
import { Hero5 } from '../../components/LoopSample/Hero5';
import { Feature } from '../../components/LoopSample/Feature';
import { Pricing } from '../../components/LoopSample/Pricing';
// import { ShowcaseSection } from '../../components/LoopSample/Showcase';
import '../../styles/scss/style.scss'

const LoopProX = () => {

  return (
    <Layout>
		  <Helmet>
			  <title>Loop Pro X UI Kit - Devwares</title>
			  <meta name="title" content="Loop Pro X UI Kit - Devwares" />
			  <meta
				  name="description"
				  content="Pro design system UI kit and templates for busy designers and startups"
			  />
			  <meta property="og:title" content="Loop Pro X UI Kit - Devwares" />
			  <meta
				  property="og:description"
				  content="Pro design system UI kit and templates for busy designers and startups"
			  />
			  <meta property="twitter:title" content="Loop Pro X UI Kit - Devwares" />
			  <meta
				  property="twitter:description"
				  content="Pro design system UI kit and templates for busy designers and startups"
			  />
			  <meta property="og:site_name" content="Devwares" />
			  <meta name="twitter:card" content="summary_large_image" />
			  <meta name="twitter:site" content="@devwares" />
			  <meta name="twitter:creator" content="@devwares" />
		  </Helmet>
			<div className="container">
				<Hero01/>
				<div className="mt-5">
      				<FeaturedIn/>
				</div>
			  <Hero />
			  <Feature />
			  <Hero3 />
			  <Hero2 />
			  <Hero4 />
			  <Hero5 />
			  {/* <ShowcaseSection /> */}
			  <Pricing/>
			</div>
    </Layout>
  );
};

export default LoopProX;
